<template>
    <div>
      <div class="wrapper">
      <div class="iq-auth-page">
           <div class="iq-banner-logo d-none d-lg-block">
               <img class="auth-image" src="./assets/images/01.png" alt="logo-img" loading="lazy" />
           </div>
           <div class="container-inside">
               <div class="main-circle circle-small"></div>
               <div class="main-circle circle-medium"></div>
               <div class="main-circle circle-large"></div>
               <div class="main-circle circle-xlarge"></div>
               <div class="main-circle circle-xxlarge"></div>
           </div>
           <div class="row d-flex align-items-center iq-auth-container w-100">
              <div class="col-10 col-xl-4 offset-xl-7 offset-1">
                  <div class="card">
                      <div class="card-body ">
                          <h3 class="text-center" style="margin-bottom:40px">Sign In</h3>
                          <b-alert class="alert-sm text-center mt-2" variant="primary" show>
                            This is a real app with Node.js backend - use
                            <br/>
                            <span class="fw-bold">"admin@flatlogic.com / password"</span>
                            <br/>
                            to login!
                          </b-alert>
                          <form class="mt" @submit.prevent="login">
                            <div class="form-group">
                                <label class="form-label" for="email-id">Email address</label>
                                <input type="email" ref="email" required  class="form-control mb-0 " id="email-id" placeholder="Enter email">
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="password">Password</label>
                                <input type="password"  ref="password" required  class="form-control mb-0" id="password" placeholder="Enter password">
                            </div>
                            <div class="text-center pb-3">
                                <button type="submit" class="btn btn-primary">{{this.isFetching ? 'Loading...' : 'Login'}}</button>
                            </div>
                          </form>
                          <p class="text-center">Don't have account?<router-link class="ms-2" to="register">Create an Account</router-link></p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
    </div>
  </template>
  
  <style src="./assets/css/hope-ui.min.css?v=2.0.0" lang="css" />
  <style src="./assets/css/pro.min.css?v=2.0.0" lang="css" />
  <style src="./assets/css/custom.min.css?v=2.0.0" lang="css" />
  <style src="./assets/css/dark.min.css?v=2.0.0" lang="css" />
  <style src="./assets/css/customizer.min.css?v=2.0.0" lang="css" />
  <style src="./assets/css/rtl.min.css?v=2.0.0" lang="css" />
  <!-- Library Bundle Script -->
  <script src="./assets/js/core/libs.min.js"></script>
  <!-- Slider-tab Script -->
  <script src="./assets/js/plugins/slider-tabs.js"></script>
  <!-- Lodash Utility -->
  <script src="./assets/vendor/lodash/lodash.min.js"></script>
  <!-- Utilities Functions -->
  <script src="./assets/js/iqonic-script/utility.js"></script>
  <!-- Settings Script -->
  <script src="./assets/js/iqonic-script/setting.js"></script>
  <!-- Settings Init Script -->
  <script src="./assets/js/setting-init.js"></script>
  <!-- External Library Bundle Script -->
  <script src="./assets/js/core/external.min.js"></script>
  <!-- Widgetchart Script -->
  <script src="./assets/js/charts/widgetcharts.js?v=2.0.0" defer></script>
  <!-- Dashboard Script -->
  <script src="./assets/js/charts/dashboard.js?v=2.0.0" defer></script>
  <script src="./assets/js/charts/alternate-dashboard.js?v=2.0.0" defer></script>
  <!-- Hopeui Script -->
  <script src="./assets/js/hope-ui.js?v=2.0.0" defer></script>
  <script src="./assets/js/hope-uipro.js?v=2.0.0" defer></script>
  <script src="./assets/js/sidebar.js?v=2.0.0" defer></script>
  <script>
  // import Widget from '@/components/Widget/Widget';
  // import {mapState, mapActions} from 'vuex';
  // import axios from 'axios'
  
  // import config from '../../config';
  // import router from '../../Routes';
  
  export default {
    name: 'LoginPage',
    // components: { Widget },
    data(){
      return {
        baseapi: localStorage.getItem("baseapi"),
      }
    },
  };
  </script>  